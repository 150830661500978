<template>
	<div id="privacy" class="pb-0">
		<v-row class="pa-0 ma-0" align="center" no-gutters>
			<v-col cols="12" class="px-0 primary bg-wavelines-secondary-dark white--text">
				<div class="mx-auto mb-5 pb-5" id="how" style="max-width: 1200px;">
					<h2 class="display-1 mt-md-5 pt-10 mb-1 text-center text-md-h3 shadowed">I&rsquo;mOkay.me</h2>
				</div>
			</v-col>
		</v-row>
		<v-row class="white mt-0">
			<v-col cols="12">
				<div class="mx-auto mb-5 pb-5 px-5 px-md-1 pt-md-10 secondary--text" id="how" style="max-width: 1000px;">
					<v-row>
						<v-col cols="12" class="hidden-sm-and-down">
							<h1 class="text-h4 text-center text-md-left">
								How it started...
							</h1>
						</v-col>
						<v-col cols="12">
							<h4>Somebody Ran a Red Light</h4>
							<p>Hi! I&rsquo;m Michael, the founder of I&rsquo;mOkay.me. I originally created this app for personal use after barely missing a traffic collision. In TV-drama fashion, my mind was flooded with thoughts of what would happen if I had been seriously injured. The pandemic was in full force so office and personal contact was limited. My family had no idea where my new home was as I had just moved. It struck me then that it could be days before anyone would realize I'm missing.</p>
							<p>
								Living alone suddenly seemed riddled with what-ifs and risks that needed to be addressed. How would anyone know if I needed help? Would my friends even know how to get in touch with my family? Who would feed my dog?!
							</p>
							<p>I needed an automated version of my mom&rsquo;s weekly "how are you doing?" phone calls, but something more than a voicemail if I didn&rsquo;t answer - something more proactive. So I built an app that would routinely send me texts to make sure I&rsquo;m okay, and alerting my mother if I didn't respond.</p>
							
							<p>Over the next few months, I turned this idea into something everyone could use to add a little more peace of mind.</p>
							
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>

export default {
	name: 'Terms',
	data: () => ({
		
	}),
	components: {
	},
	computed: {
	},
	methods: {
		initData(){
		},
	},
	beforeDestroy(){
	},
	mounted(){
		this.initData();
	},
	watch: {
		
	}
}
</script>